/* unplugin-vue-components disabled */import "core-js/modules/es.function.name.js";
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-5b6b61b6"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["src"];
import { resizeIframe } from '@/js/functions';
export default {
  __name: 'MIframe',
  props: {
    src: String
  },
  emits: ['message', 'init', 'resizeError', 'load'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      iframeLoaded: false,
      iframeOpacity: 1,
      scrollHeight: 0
    });
    var refIframe = ref(null); // 工具函式 - 設定高度

    function pullHeight(el, binding) {
      if (el) {
        var rect = el.getBoundingClientRect();
        var minus = binding && binding.minus || 0; // set height for autoscroll

        el.style.height = "".concat(window.innerHeight - rect.top - minus, "px");
      }
    } // 工具函式 - 設定高度


    function pullScrollHeight() {
      // 高度資料可能會晚於此於function執行
      watch(function () {
        return cData.scrollHeight;
      }, function () {
        if (cData.scrollHeight) {
          refIframe.value.style.height = "".concat(cData.scrollHeight, "px");
        }
      }, {
        immediate: true
      });
    }

    function onIframeLoad() {
      try {
        console.log('resizeIframe');
        resizeIframe(refIframe.value);
      } catch (err) {
        console.log('SecurityError');

        if (err.name == 'SecurityError') {
          // cors error
          emit('resizeError', {
            iframe: refIframe.value,
            pullHeight: pullHeight.bind(null, refIframe.value),
            pullScrollHeight: pullScrollHeight
          });
        }
      }

      cData.iframeLoaded = true;
      emit('load');
      setTimeout(function () {
        cData.iframeOpacity = 1;
      }, 50);
    }

    function onMessage(event) {
      emit('message', event);

      if (event.data && event.data.data) {
        if (event.data.data.scrollHeight) {
          console.log('onMessage', event.data.data.scrollHeight);
          cData.scrollHeight = event.data.data.scrollHeight;
        }
      }
    }

    onMounted(function () {
      if (refIframe.value) {
        var contentWindow = refIframe.value.contentWindow;

        if (contentWindow) {
          window.addEventListener("message", onMessage, false);
        }
      }

      emit('init', {
        window: refIframe.value && refIframe.value.contentWindow
      });
    });
    onUnmounted(function () {
      window.removeEventListener("message", onMessage);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("iframe", {
        ref_key: "refIframe",
        ref: refIframe,
        class: "m-iframe",
        src: __props.src,
        frameborder: "0",
        style: _normalizeStyle({
          'flex-grow': 1,
          'opacity': _unref(cData).iframeOpacity
        }),
        onLoad: onIframeLoad,
        sandbox: "allow-modals allow-scripts allow-same-origin allow-downloads"
      }, null, 44, _hoisted_1);
    };
  }
};