/* unplugin-vue-components disabled */import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MModal.vue';
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VuePdfEmbed from 'vue-pdf-embed';
export default {
  __name: 'PdfModal',
  props: {
    visible: Boolean,
    src: String
  },
  setup: function setup(__props) {
    var props = __props;
    var cData = reactive({
      iframeLoaded: false
    });

    function onIframeLoaded() {
      setTimeout(function () {
        cData.iframeLoaded = true;
      }, 50);
    }

    watch(function () {
      return props.src;
    }, function () {}, {
      immediate: true
    });
    watch(function () {
      return props.visible;
    }, function () {
      if (!props.visible) {
        cData.iframeLoaded = false;
      }
    });
    return function (_ctx, _cache) {
      var _component_MModal = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_MModal, {
        "dismissable-mask": "",
        "close-btn": "",
        class: _normalizeClass(["pdf-modal", [_unref(cData).iframeLoaded ? 'pdf-modal_iframe-load' : '']]),
        visible: __props.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.$emit('update:visible', $event);
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_unref(VuePdfEmbed), {
            source: __props.src,
            onLoaded: onIframeLoaded
          }, null, 8, ["source"])];
        }),
        _: 1
      }, 8, ["class", "visible"]);
    };
  }
};